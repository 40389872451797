import React from 'react';
import { graphql, Link } from 'gatsby';
import P from 'prop-types';

import Layout from '../layouts/index';

const Bloggs = ({ data }) => {
  const { nodes } = data.allMarkdownRemark;

  const author = nodes[0].frontmatter.author.name;

  return (
    <Layout bodyClass="page-blogg">
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>{`${author}'s blogg`}</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div>
          {nodes.map((node) => {
            const { name, avatar, key } = node.frontmatter.author;
            const { slug } = node.frontmatter;
            return (
              <div className="" key={node.id}>
                <div className="team card-two">
                  <div className="card-header" style={{ width: '100%' }}>
                    <div className="card-header-left">
                      {avatar && (
                        <div className="card-image">
                          <Link to={`/konsulter/${key}`}>
                            <img
                              alt={name}
                              src={avatar}
                              style={{ width: '100%' }}
                            />
                          </Link>
                        </div>
                      )}
                    </div>
                    <div className="card-right">
                      <ul className="card-meta d-flex flex-column">
                        <li><h4 className="align-left"><strong>{name}</strong></h4></li>
                        <li className="hide-on-mobile"><p className="card-text align-left">{node.excerpt}</p></li>
                        <li className="hide-on-mobile"><Link to={`/blogg/${key}/${slug}`}><p className="card-text">LÄS MER ⯈</p></Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="content display-on-mobile">
                  <p>{node.excerpt}</p>
                  <Link to={`/blogg/${key}/${slug}`}>LÄS MER ⯈</Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>

    </Layout >
  );
};

export const query = graphql`
  query($author: String) {
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/blogg/"}, frontmatter: {author: {key: {eq: $author}}}}
    ) {
      nodes {
        id
        frontmatter {
        slug
          author {
            name
            avatar
            key
          }
        }
        excerpt
        html
      }
    }
  }
`;

Bloggs.propTypes = {
  data: P.shape({
    allMarkdownRemark: P.shape({
      nodes: P.arrayOf(P.object),
    }).isRequired,
  }).isRequired,
};

export default Bloggs;
